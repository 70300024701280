import useAxios from 'axios-hooks';
import { useApiClient } from '../apiClient';
import { ProductOffer } from '../models/product-offer';
import { ProductRequest } from '../models/product-request'; // Ensure this import exists

export const useProductService = () => {
    const { instance } = useApiClient(); // This sets up the authenticated axios instance

    return {
        useSearchProducts: (url: string) => useAxios<ProductRequest>({
            url: '/api/product/search',
            method: 'GET',
            params: { url: url }
        }, { manual: true }),
        useGetProductOffers: (slug: string) => useAxios<ProductOffer[]>({
            url: `/api/user/product-request/${slug}/product-offers`,
            method: 'GET'
        }),
        useGetProductRequests: () => useAxios<ProductRequest[]>({
            url: '/api/user/product-request/public',
            method: 'GET'
        }),
        useGetProductRequestBySlug: (slug: string) => useAxios<ProductRequest>({
            url: `/api/user/product-request/public/${slug}`,
            method: 'GET'
        }),
        useProductRequestRefresh: (slug: string) => useAxios<ProductRequest>({
            url: '/api/product/refresh',
            method: 'GET',
            params: { slug },
        }, { manual: true }),
        useProductRequestView: (slug: string) => useAxios<ProductRequest>({
            url: `/api/user/product-request/${slug}/view`,
            method: 'POST',
        }),
        useProductOfferRedirect: (productOfferId: number) => useAxios<ProductOffer>({
            url: `/api/user/product-request/product-offers/${productOfferId}/redirect`,
            method: 'POST',
        }, { manual: true }),
    };
};