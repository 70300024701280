import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1f4be9', // Color from the gradient
      light: '#647bf2', // A lighter shade of the primary color
      dark: '#0023b5', // A darker shade of the primary color
    },
    secondary: {
      main: '#44e5fb', // Color from the gradient
      light: '#76f0fc', // A lighter shade of the secondary color
      dark: '#00b2c7', // A darker shade of the secondary color
    },
    error: {
      main: '#e53935', // Slightly darker red for better contrast
    },
    background: {
      default: '#e7ebee', // A cooler shade of gray to complement the primary and secondary colors
      paper: '#ffffff', // Keeping paper as pure white for clarity
    },
    text: {
      primary: '#212121', // Darker for better readability
      secondary: '#474747', // Also a bit darker to enhance readability
    },
    info: {
      main: '#B0BEC5', // Slightly darker red for better contrast
      light: '#B0BEC5',  // Custom light gray color
    },
  },
});

export default theme;
