import React from "react";

const LinkedinIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      style={{ cursor: "pointer" }}
    >
      <path
        d="M12.002 9.55005C12.9191 8.61327 14.1131 8 15.502 8C18.5395 8 21.002 10.4624 21.002 13.5V21H19.002V13.5C19.002 11.567 17.435 10 15.502 10C13.569 10 12.002 11.567 12.002 13.5V21H10.002V8.5H12.002V9.55005ZM5.00195 6.5C4.17352 6.5 3.50195 5.82843 3.50195 5C3.50195 4.17157 4.17352 3.5 5.00195 3.5C5.83037 3.5 6.50195 4.17157 6.50195 5C6.50195 5.82843 5.83037 6.5 5.00195 6.5ZM4.00195 8.5H6.00195V21H4.00195V8.5Z"
        fill="#1A6BC4"
      />
    </svg>
  );
};

export default LinkedinIcon;
