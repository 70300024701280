import React, { useEffect, useState } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { formatDistanceToNow } from 'date-fns';

interface RelativeDateDisplayProps {
    date: string | Date;
    typographyProps?: TypographyProps;
}

export const RelativeDateDisplay: React.FC<RelativeDateDisplayProps> = ({ date, typographyProps }) => {
    const [relativeTime, setRelativeTime] = useState('');

    const updateRelativeTime = () => {
        const parsedDate = typeof date === 'string' ? new Date(date) : date;
        setRelativeTime(formatDistanceToNow(parsedDate, { addSuffix: true }));
    };

    useEffect(() => {
        updateRelativeTime();
        const timer = setInterval(updateRelativeTime, 60000); // Update every minute

        return () => clearInterval(timer);
    }, [date]);

    return (
        <Typography {...typographyProps}>
            {relativeTime}
        </Typography>
    );
};