import React, { ReactNode } from "react";
import "./Button.css";

interface ButtonProps {
  onClick: () => void;
  text: string;
  className?: string;
  textClassName?: string;
  icon?: ReactNode;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  text,
  className,
  textClassName,
  icon,
  loading
}) => {
  return (
    <div
      onClick={loading ? undefined : onClick} // Disable onClick when loading
      className={
        "button" + (!!className ? ` ${className}` : "") + (loading ? " loading" : "")
      }
    >

      <div
        className={
          "button-text" + (!!textClassName ? ` ${textClassName}` : "")
        }
      >
        {text}
      </div>
      {icon}
      {loading && <div className="spinner"></div>} {/* Smaller spinner on the left */}
    </div>
  );
};

export default Button;
