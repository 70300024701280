import React from "react";
import "./About.css";

interface ChipProps {
  text: string;
}

const Chip: React.FC<ChipProps> = ({ text }) => {
  return (
    <div className="about-chip-container">
      <div className="about-chip-text">{text}</div>
    </div>
  );
};

export default Chip;
