import axios from 'axios';
import { configure } from 'axios-hooks';
import { useAuthService } from './services/authService';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://your-api-base-url.com';

export const useApiClient = () => {
    const { useGuestLogin } = useAuthService();
    const [, getAccessToken] = useGuestLogin();

    const instance = axios.create({
        baseURL: API_BASE_URL,
    });

    instance.interceptors.request.use(async (config) => {
        // Ignore token for guest login endpoint
        if (config.url === '/api/auth/guest') {
            return config;
        }

        try {
            let token = localStorage.getItem('authToken');

            if (!token) {
                const response = await getAccessToken();
                token = response.data.token;
                localStorage.setItem('authToken', token);
            }

            config.headers.Authorization = `Bearer ${token}`;
        } catch (error) {
            console.error('Error getting access token', error);
        }
        return config;
    });

    instance.interceptors.response.use(
        response => response,
        async (error) => {
            console.log('Response interceptor caught an error:', error.response?.status);
            if (error.response && error.response.status === 401 && !error.config._retry) {
                console.log('Attempting to refresh token due to 401 error');
                error.config._retry = true;
                try {
                    const response = await getAccessToken();
                    const newToken = response.data.token;
                    console.log('New token obtained successfully');
                    localStorage.setItem('authToken', newToken);
                    error.config.headers['Authorization'] = `Bearer ${newToken}`;
                    console.log('Retrying original request with new token');
                    return instance(error.config);
                } catch (refreshError) {
                    console.error('Token refresh failed:', refreshError);
                    return Promise.reject(refreshError);
                }
            }
            console.log('Error was not 401 or retry already attempted, rejecting promise');
            return Promise.reject(error);
        }
    );

    configure({ axios: instance });

    return { instance, getAccessToken };
};