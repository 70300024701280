import Background from "./Background";
import "./HeroSection.css";
import IntroText from "./IntroText";
import Link from "./Link";

const HeroSection = () => {
  return (
    <div className="hero-section-container">
      <Background />
      <div className="hero-section">
        <div className="hero-section-left">
          <IntroText />
          <Link />
        </div>
        <div className="hero-video-section-container">
          <img src="chat.webp" className="hero-video" alt="Demo"></img>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
