import React, { useEffect, useState } from "react";
import "./HeroSection.css";

const Background: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <img
      className="background"
      src={isMobile ? "Background2_mobile.webp" : "Background2.webp"}
      alt="Background"
    />
  );
};

export default Background;
