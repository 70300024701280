import { useAuth0 } from "@auth0/auth0-react";
import Navbar from "../Landing/Navbar/Navbar";
import Input from "../Landing/common/Input/Input";
import ProductHistoryPanel from "./ProductHistory/ProductHistoryPanel";
import { Box, Typography, IconButton, useMediaQuery } from "@mui/material";
import { styled } from '@mui/material/styles';
import theme from "../../theme";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../Shared/ProgressBar";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useProductService } from "../../api/services/productSearchService";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Tooltip from '@mui/material/Tooltip';
import { useAuth } from "../../api/utils";

function Dashboard() {
    const [isLoading, setIsLoading] = useState(false);
    const [showProgressBar, setShowProgressBar] = useState(false);
    const navigate = useNavigate();
    const { useSearchProducts } = useProductService();
    const [{ data, loading, error }, executeSearch] = useSearchProducts("");
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { country } = useAuth();

    const processUrl = async (url: string) => {
        setIsLoading(true);
        try {
            const result = await executeSearch({ params: { url: url } });
            if (result.data && result.data.id) {
                navigate(`/${country}/app/search/${result.data.slug}`);
            } else {
                // Handle case when no product-request is created
                console.log("Failed to create product-request");
            }
        } catch (error) {
            console.error("Error creating product-request:", error);
        } finally {
            setIsLoading(false);
        }
    }

    const toggleProgressBar = () => {
        setShowProgressBar(!showProgressBar);
    }
    console.log("data", data)

    return (
        <ScrollableContent>
            <Box sx={{ padding: 3 }}>
                <CustomBox>
                    <TopPanelContainer isMobile={isMobile}>
                        <InstructionsContainer>
                            <>
                                <InstructionText>
                                    <ShoppingCartIcon sx={{ fontSize: '1rem', marginRight: '5px' }} />
                                    Paste a product URL from any e-commerce site to find deals from multiple providers
                                </InstructionText>
                                <StepsContainer>
                                    <StepText>1. Copy URL</StepText>
                                    <StepDivider>→</StepDivider>
                                    <StepText>2. Paste below</StepText>
                                    <StepDivider>→</StepDivider>
                                    <StepText>3. Click "Find Deals"</StepText>
                                </StepsContainer>
                            </>
                        </InstructionsContainer>
                        <InputContainer>
                            {isLoading && <ProgressBarContainer><ProgressBar isVisible={showProgressBar} /></ProgressBarContainer>}
                            <Input
                                validationType="url"
                                style={inputStyle}
                                loading={isLoading}
                                buttonText={"Find Deals"}
                                placeHolderText="https://www.amazon.com/product-name/dp/ASIN"
                                onButtonClick={processUrl}
                            />
                        </InputContainer>

                        <ToggleTextContainer>
                            {isLoading ? (
                                <ToggleText>
                                    If you want to check the progress, click the eye button
                                    <IconButton
                                        onClick={toggleProgressBar}
                                        sx={{
                                            marginLeft: "5px",
                                        }}
                                    >
                                        {showProgressBar ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </ToggleText>
                            ) : (
                                <div style={{ height: '24px' }} /> // Placeholder to maintain height
                            )}
                        </ToggleTextContainer>
                    </TopPanelContainer>
                    <ProductHistoryPanel />
                </CustomBox>
            </Box>
        </ScrollableContent>
    );
}

const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    height: "100vh",
    alignItems: "center"
}));

const TopPanelContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isMobile',
})<{ isMobile?: boolean }>(({ theme, isMobile }) => ({
    display: 'flex',
    flexDirection: "column",
    alignItems: 'center',
    marginBottom: isMobile ? 0 : 20,
    marginTop: 20,
    gap: 5
}));

const InputContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
}));

const ProgressBarContainer = styled(Box)(({ theme }) => ({
    marginRight: 20,
    position: "absolute",
    right: -200,
    marginTop: 30
}));

const inputStyle: React.CSSProperties = {
    border: `1px solid ${theme.palette.divider}`
};

const ToggleTextContainer = styled(Box)(({ theme }) => ({
    height: '24px', // Adjust this value to match the height of your ToggleText
    display: 'flex',
    alignItems: 'center',
    marginBottom: 20,
}));

const ToggleText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    cursor: 'pointer',
    fontSize: '0.675rem',
    display: 'flex',
    alignItems: 'center',
}));

const InstructionText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.75rem',
    marginBottom: '10px',
    textAlign: 'center',
    maxWidth: '600px',
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'center',
}));

const StepText = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
    fontSize: '0.8rem',
    fontWeight: 500,
}));

const StepDivider = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.secondary,
    fontSize: '0.8rem',
    margin: '0 10px',
}));

const ScrollableContent = styled(Box)({
    height: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
});

const StepsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '15px',
}));

const InstructionsContainer = styled(Box)(({ theme }) => ({
    height: '80px', // Adjust this value to match the height of your instructions
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

export default Dashboard;
