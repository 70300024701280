import React, { useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { User, useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Logout from '@mui/icons-material/Logout'; // or import ExitToApp from '@mui/icons-material/ExitToApp';
import Title from '../Landing/Navbar/Title/Title';
import { styled } from '@mui/material/styles';
import AccountIcon from './Icons/AccountIcon';
import { Link } from 'react-router-dom';

interface Props {
    isDrawerOpen: boolean;
    onDrawerChange?: React.Dispatch<React.SetStateAction<boolean>>
    children?: any;
    shouldDisplayDrawer: boolean;
}
const AppHeader = ({ isDrawerOpen, children, onDrawerChange, shouldDisplayDrawer }: Props) => {
    const [isDrawerOpenInternal, setIsDrawerOpenInternal] = React.useState(isDrawerOpen);

    const { user, loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleDrawerToggle = () => {
        if (onDrawerChange) {
            onDrawerChange(!isDrawerOpenInternal);
        }
        setIsDrawerOpenInternal(!isDrawerOpenInternal);
    };

    const drawer = children

    useEffect(() => {
        setIsDrawerOpenInternal(isDrawerOpen)
    }, [isDrawerOpen])

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box sx={{ flexGrow: 0 }}>
            <CustomAppBar position="sticky">
                <CustomToolbar variant="dense">
                    {shouldDisplayDrawer && (
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{ display: { sm: 'none' } }}
                        >
                            <MenuIcon color='primary' />
                        </IconButton>
                    )}
                    <Link to="/app" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none' }}>
                        <Title />
                    </Link>
                    <div>
                        {/* <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleMenu}
                            color="inherit"
                        >
                            <AccountIcon />
                        </IconButton> */}
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorEl}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            {isAuthenticated && user && user.email && (
                                <MenuItem style={{ color: 'gray', pointerEvents: 'none', fontSize: "14px" }}>
                                    {user.email}
                                </MenuItem>
                            )}
                            <Divider />
                            <MenuItem onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}>
                                <Logout sx={{ marginRight: 1 }} />
                                Logout
                            </MenuItem>
                        </Menu>
                    </div>
                </CustomToolbar>
            </CustomAppBar>
            <nav>
                {shouldDisplayDrawer && (
                    <Drawer
                        variant="temporary"
                        open={isDrawerOpenInternal}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: 'block', sm: 'none' },
                            '& .MuiDrawer-paper': { boxSizing: 'border-box' },
                        }}
                    >
                        {drawer}
                    </Drawer>
                )}
            </nav>
        </Box>
    );
};

export default AppHeader;

const CustomAppBar = styled(AppBar)(({ theme }) => ({
    background: 'var(--Neutral-N24, rgba(255, 255, 255, 0.24))',
    boxShadow: 'rgba(149, 157, 165, 0.1) 0px 8px 24px',
    backdropFilter: 'blur(44px)',
    padding: '0 0 0 132px',
    height: '65px',
    position: 'sticky',
    top: 0,
    zIndex: theme.zIndex.appBar,
    [theme.breakpoints.down('md')]: {
        padding: '0 0 0 100px', // Reduced padding for medium screens (e.g., tablets)
    },
    [theme.breakpoints.down('sm')]: {
        padding: '0 10px 0 10px', // Further reduced padding for small screens (e.g., mobile devices)
    },
}));

const CustomToolbar = styled(Toolbar)(({ theme }) => ({
    height: '65px',
    paddingLeft: '0px',
    justifyContent: "space-between"
}));