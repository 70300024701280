import React, { createContext, useContext, useState, ReactNode } from "react";

export type Joined = "NOT_JOINED" | "NEWLY_JOINED" | "OLD_JOINED";

interface WaitlistContextType {
    joined: Joined;
    setJoined: (joined: Joined) => void;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    buttonText: string;
    setButtonText: (text: string) => void;
}

const WaitlistContext = createContext<WaitlistContextType | undefined>(undefined);

export const WaitlistProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [joined, setJoined] = useState<Joined>("NOT_JOINED");
    const [loading, setLoading] = useState(false);
    const [buttonText, setButtonText] = useState("Join Waitlist");

    return (
        <WaitlistContext.Provider value={{ joined, setJoined, loading, setLoading, buttonText, setButtonText }}>
            {children}
        </WaitlistContext.Provider>
    );
};

export const useWaitlist = () => {
    const context = useContext(WaitlistContext);
    if (!context) {
        throw new Error("useWaitlist must be used within a WaitlistProvider");
    }
    return context;
};
