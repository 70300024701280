export interface ProductOffer {
    id: number;
    userId: number;
    regularItemUrl: string;
    affilateLink: string;
    productAttributes: ProductAttribute[];
    state: UserProductOfferState;
    type: UserProductOfferType;
    providerEntity: ProductOfferProvider;
}

export interface ProductOfferProvider {
    name: string;
    id: string;
}
export interface ProductAttribute {
    propertyName: string;
    propertyValue: string;
}

export enum Provider {
    Ebay = 0,
    Amazon = 1,
    Farfetch = 2,
    Lyst = 3,
    Asos = 4,
    SSense = 5,
    Mytheresa = 6
}

// Add this helper function to get the provider name
export function getProviderName(providerValue: number): string {
    return Provider[providerValue] || 'Unknown';
}

export enum UserProductOfferState {
    Normal,
    Starred,
    NotInterested
}

export enum UserProductOfferType {
    Regular,
    GeneratedFromLink,
}


export const ProductAttributeNames = {
    Title: "Title",
    Description: "Description",
    Price: "Price",
    Condition: "Condition",
    Color: "Color",
    Size: "Size",
    Brand: "Brand",
    Material: "Material",
    Seller: "Seller",
    SellerFeedbackScore: "SellerFeedbackScore",
    SellerFeedbackPercentage: "SellerFeedbackPercentage",
    ImageUrl: "ImageUrl",
    DesignerName: "DesignerName",
    FullPrice: "FullPrice",
    SalePrice: "SalePrice",
    Gender: "Gender",
    ShortDescription: "ShortDescription",
    RetailerName: "RetailerName",
    InStock: "InStock",
    StockQuantity: "StockQuantity",
    DiscountInfo: "DiscountInfo",
    PromoLabel: "PromoLabel",
    DateAdded: "DateAdded",
    LowQualityImageUrl: "LowQualityImageUrl",
    Currency: "Currency",
    InitialUserRequestedProductBrand: "InitialUserRequestedProductBrand",
    InitialUserRequestedProductPrice: "InitialUserRequestedProductPrice"
} as const;
