import React, { createContext, useContext, useEffect, useState } from 'react';
import { useApiClient } from './apiClient';
import { getAttributeValue } from './models/seo.json';
import { ProductAttribute, ProductAttributeNames } from './models/product-offer';

interface AuthContextType {
    isReady: boolean;
    country: string | null;
    currency: string | null;
    currencySymbol: string;
}

const AuthContext = createContext<AuthContextType>({
    isReady: false,
    country: null,
    currency: null,
    currencySymbol: '$'
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const { getAccessToken } = useApiClient();
    const [isReady, setReady] = useState(false);
    const [country, setCountry] = useState<string | null>(null);
    const [currency, setCurrency] = useState<string | null>(null);
    const [currencySymbol, setCurrencySymbol] = useState('$');

    useEffect(() => {
        const initializeAuth = async () => {
            let token = localStorage.getItem('authToken');

            if (!token) {
                try {
                    const response = await getAccessToken();
                    token = response.data.token;
                    localStorage.setItem('authToken', token);
                } catch (e) {
                    console.error('Failed to get guest token:', e);
                }
            }

            if (token) {
                try {
                    const decodedToken = JSON.parse(atob(token.split('.')[1]));
                    const country = (decodedToken["https://tryscouter.com/country"] || "").toLowerCase();
                    const currency = decodedToken["https://tryscouter.com/currency"] || null;
                    setCountry(country);
                    setCurrency(currency);
                    setCurrencySymbol(getCurrencySymbol(currency));
                    setReady(true);
                } catch (error) {
                    console.error('Error decoding token:', error);
                }
            }
        };

        initializeAuth();
    }, [getAccessToken]);

    if (!isReady) {
        return null; // or a loading spinner
    }

    return <AuthContext.Provider value={{ isReady, country, currency, currencySymbol }}>
        {children}
    </AuthContext.Provider>
};

export const useAuth = () => useContext(AuthContext);

// Add this function to get the currency symbol
export function getCurrencySymbol(currency: string | null): string {
    const currencySymbols: { [key: string]: string } = {
        USD: '$',   // United States Dollar
        EUR: '€',   // Euro
        GBP: '£',   // British Pound Sterling
        JPY: '¥',   // Japanese Yen
        CNY: '¥',   // Chinese Yuan
        AUD: 'A$',  // Australian Dollar
        CAD: 'C$',  // Canadian Dollar
        CHF: 'Fr',  // Swiss Franc
        HKD: 'HK$', // Hong Kong Dollar
        NZD: 'NZ$', // New Zealand Dollar
        SEK: 'kr',  // Swedish Krona
        KRW: '₩',   // South Korean Won
        SGD: 'S$',  // Singapore Dollar
        NOK: 'kr',  // Norwegian Krone
        MXN: '$',   // Mexican Peso
        INR: '₹',   // Indian Rupee
        RUB: '₽',   // Russian Ruble
        ZAR: 'R',   // South African Rand
        TRY: '₺',   // Turkish Lira
        BRL: 'R$',  // Brazilian Real
        TWD: 'NT$', // New Taiwan Dollar
        DKK: 'kr',  // Danish Krone
        PLN: 'zł',  // Polish Złoty
        THB: '฿',   // Thai Baht
        IDR: 'Rp',  // Indonesian Rupiah
        HUF: 'Ft',  // Hungarian Forint
        CZK: 'Kč',  // Czech Koruna
        ILS: '₪',   // Israeli New Shekel
        CLP: '$',   // Chilean Peso
        PHP: '₱',   // Philippine Peso
        AED: 'د.إ', // United Arab Emirates Dirham
        COP: '$',   // Colombian Peso
        SAR: '﷼',   // Saudi Riyal
        MYR: 'RM',  // Malaysian Ringgit
        RON: 'lei', // Romanian Leu
        GEL: '$',   // Georgian Lari
    };
    return currency ? (currencySymbols[currency] || currency) : '$';
}

export const useCurrencySymbol = (productAttributes: ProductAttribute[]) => {
    const { currencySymbol: authCurrencySymbol } = useAuth();
    const offerCurrency = getAttributeValue(productAttributes, ProductAttributeNames.Currency);
    const realCurrencySymbol = offerCurrency ? getCurrencySymbol(offerCurrency) : authCurrencySymbol;

    return realCurrencySymbol;
};