import AboutSection from "./About/About";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./Footer/Footer";
import HeroSection from "./HeroSection/HeroSection";
import { WaitlistProvider } from "./HeroSection/Waitlist/WaitlistContext";
import Navbar from "./Navbar/Navbar";

function Landing() {

    return (
        <>
            <WaitlistProvider>
                <Navbar />
                <HeroSection />
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <AboutSection />
                </div>
                <Footer />
            </WaitlistProvider>
        </>
    );
}

export default Landing;
