import React, { useState, useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PropsOf } from '@emotion/react';

// Custom styled components for the vertical progress bar
const ProgressBarContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '100%',
}));

const StepContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: '24px',
}));

const ProgressLine = styled(Box)<{ progress: number }>(({ theme, progress }) => ({
    width: 1.5,
    backgroundColor: theme.palette.grey[400], // The color of the growing line
    height: `${progress}%`, // Start from 0 and grow to 100%
    position: 'absolute',
    top: '24px', // To connect from the bottom of the circle
    left: "12px",
    transform: 'translateX(-50%)',
    transition: 'height 0.3s ease-in-out',
}));

const StepCircle = styled(Box)<{ active: boolean; completed: boolean }>(({ theme, active, completed }) => ({
    width: 24,
    height: 24,
    borderRadius: '50%',
    backgroundColor: completed ? 'green' : active ? theme.palette.primary.light : theme.palette.grey[400],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    zIndex: 1,
    transition: 'background-color 0.3s ease-in-out',
}));

const durations = [4000, 6000];

interface Props {
    isVisible: boolean
}
const VerticalProgressBar = ({ isVisible }: Props) => {
    const steps = ['Building Query', 'Searching', 'AI Ranking'];
    const [activeStep, setActiveStep] = useState(0);
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (activeStep < steps.length) {
            setProgress(0); // Reset progress when a new step starts
            const stepDuration = durations[activeStep] / 100; // Duration for each increment

            const interval = setInterval(() => {
                setProgress((prev) => {
                    const newProgress = prev + 1;
                    if (newProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => {
                            setActiveStep((prevStep) => prevStep + 1);
                        }, 500); // Small delay before moving to the next step
                    }
                    return newProgress;
                });
            }, stepDuration);

            return () => clearInterval(interval);
        }
    }, [activeStep]);

    return (isVisible ?
        <Box sx={{ width: '150px', margin: '0 auto', padding: '16px' }}>
            <ProgressBarContainer>
                {steps.map((label, index) => (
                    <StepContainer key={label}>
                        <StepCircle active={activeStep === index} completed={activeStep > index}>
                            {activeStep > index ? <CheckCircleIcon fontSize="small" /> : index + 1}
                        </StepCircle>
                        {index < steps.length - 1 && (
                            <ProgressLine progress={activeStep > index ? 100 : activeStep === index ? progress : 0} />
                        )}
                        <Typography variant="caption" sx={{ marginLeft: '12px' }}>
                            {label}
                        </Typography>
                    </StepContainer>
                ))}
            </ProgressBarContainer>
        </Box> : <></>
    );
};

export default VerticalProgressBar;
