import ArrowIcon from "./ArrowIcon";
import "./HeroSection.css";

const Link = () => {
  return (
    <div className="intro-link-container" onClick={() => {
      const element = document.getElementById('about-section-container');
      console.log("CLICKED");
      console.log(element);
      element?.scrollIntoView({
        behavior: 'smooth'
      })
    }
    }>
      <div className="intro-link-text">What is it about?</div>
      <ArrowIcon />
    </div >
  );
};

export default Link;
