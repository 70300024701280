import React from "react";
import "./Footer.css";
import LinkedinIcon from "./LinkedinIcon";
import InstagramIcon from "./InstagramIcon";
import XIcon from "./XIcon";
import Title from "../Navbar/Title/Title";

const Footer: React.FC = () => {
  return (
    <footer className="footer-container">
      <Title className="footer-title" />
      <div className="footer-rights">
        All rights reserved <br className="line-breaker"></br> © 2024 Scouter Inc.
      </div>
      <div className="footer-socials-container">
        <XIcon></XIcon>
        <LinkedinIcon />
      </div>
    </footer>
  );
};

export default Footer;
