import React, { ReactNode } from "react";
import "../Navbar.css";

const Links: React.FC = () => {
  return (
    <div className="links-container">
      {/* <Link href="/">Homepage</Link>
      <Link href="/products">Products</Link>
      <Link href="/pricing">Pricing</Link> */}
    </div>
  );
};

export default Links;

const Link = ({ children, href }: { children: ReactNode; href: string }) => {
  return (
    <a href={href} className="link">
      {children}
    </a>
  );
};
