import React, { useRef } from "react";
import "./Input.css";
import Button from "../Button/Button";

interface InputProps {
  onButtonClick: (inputValue: string) => void;
  buttonText: string;
  placeHolderText: string;
  validationType: 'email' | 'url';  // New prop to define the validation type
  style?: React.CSSProperties;
  loading?: boolean;
}

const Input: React.FC<InputProps> = ({
  onButtonClick,
  buttonText,
  placeHolderText,
  validationType,
  style,
  loading = false,
}) => {
  const [inputValue, setInputValue] = React.useState<string>("");
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (inputValue) {
      onButtonClick(inputValue);
      setInputValue('');  // Clear input after submission
    }
  };

  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.requestSubmit();
    }
  };

  return (
    <form style={style} ref={formRef} onSubmit={handleSubmit} className="input-container">
      <input
        type={validationType === 'email' ? 'email' : 'url'}  // Conditional type based on validationType prop
        className="input"
        placeholder={placeHolderText}
        value={inputValue}
        disabled={loading}
        onChange={(e) => setInputValue(e.target.value)}
        required
        pattern={validationType === 'url' ? "https?://.+" : undefined} // Custom pattern for URL validation
      />
      <Button
        loading={loading}
        text={buttonText}
        onClick={handleButtonClick}
        className="short"
      />
    </form>
  );
};

export default Input;
