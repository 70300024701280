import { ProductOffer, ProductAttributeNames } from "./product-offer";
import { ProductRequest } from "./product-request";

const generateStructuredData = (request: ProductRequest, productOffers: ProductOffer[]) => {
    return {
        "@context": "https://schema.org/",
        "@type": "Product",
        "name": request.searchTerm,
        "image": getAttributeValue(request.productAttributes, ProductAttributeNames.ImageUrl) || "https://example.com/default-image.jpg",
        "description": `Find the best price for ${request.searchTerm}.`,
        "offers": generateOffersSchema(productOffers)
    };
};

const generateOffersSchema = (productOffers: ProductOffer[]) => {
    return productOffers.map((offer) => ({
        "@type": "Offer",
        "url": offer.regularItemUrl || window.location.href,
        "priceCurrency": "USD", // Assuming USD as default
        "price": getAttributeValue(offer.productAttributes, ProductAttributeNames.SalePrice) ||
            getAttributeValue(offer.productAttributes, ProductAttributeNames.FullPrice),
        "availability": "https://schema.org/InStock",
        "seller": {
            "@type": "Organization",
            "name": getAttributeValue(offer.productAttributes, ProductAttributeNames.Brand) || "Unknown Seller"
        }
    }));
};

export const getAttributeValue = (attributes: ProductOffer['productAttributes'], name: string) => {
    const value = attributes.find(attr => attr.propertyName === name)?.propertyValue || '';
    if (name === ProductAttributeNames.ImageUrl && !value.startsWith('http')) {
        return `https://${value}`;
    }
    return value;
};

export const getAttributeValueAsNumber = (attributes: ProductOffer['productAttributes'], name: string): number => {
    const value = getAttributeValue(attributes, name);
    return parseFloat(value) || 0;
};

export { generateStructuredData, generateOffersSchema };
