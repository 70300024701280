import { Card, CardContent, Typography, Box, CardMedia, Tooltip, IconButton, Chip } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ProductRequest, ProductRequestVisibility } from "../../../api/models/product-request";
import { getImageUrl } from "../../../api/utils/productRequestUtils";
import { RelativeDateDisplay } from "../../Shared/RelativeDateDisplay";
import PublicIcon from '@mui/icons-material/Public'; // New import for community icon
import { useAuth, useCurrencySymbol } from "../../../api/utils";

export interface ProductHistoryCardProps {
    request: ProductRequest;
    onClick: () => void;
    isInWishlist: boolean;
    onToggleWishlist: () => void;
}

const ProductHistoryCard = ({ request, onClick, isInWishlist, onToggleWishlist }: ProductHistoryCardProps) => {
    const currencySymbol = useCurrencySymbol(request?.productAttributes || []);


    return (
        <StyledCard onClick={onClick}>
            <ImageWrapper>
                <CardMedia
                    component="img"
                    image={getImageUrl(request)}
                    alt={request.searchTerm}
                    sx={{ objectFit: 'cover', width: '100%', height: '100%' }}
                />
                {request.visibility === ProductRequestVisibility.Public && (
                    <CommunityBadge>
                        <Tooltip title="Community Request">
                            <PublicIcon fontSize="small" />
                        </Tooltip>
                    </CommunityBadge>
                )}
            </ImageWrapper>
            <CardContent style={{ padding: "10px" }}>
                <Box display="flex" flexDirection="column">
                    <Typography variant="body1" component="div" color="textPrimary" fontWeight="bold">
                        {request.searchTerm}
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <Typography variant="subtitle1" >
                            {currencySymbol}{request.price}
                        </Typography>

                    </Box>
                    <Box display="flex" alignItems="center">
                        <RelativeDateDisplay
                            date={request.updateDate}
                            typographyProps={{
                                variant: "caption",
                                sx: { fontSize: '10px' },
                                color: "textSecondary",
                                noWrap: true
                            }}
                        />
                    </Box>
                </Box>
            </CardContent>
        </StyledCard>
    );
};

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: "330px",
    minWidth: "330px",
    margin: theme.spacing(2),
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Reduced shadow
    '&:hover': {
        transform: 'translateY(-4px)', // Subtle lift instead of scale
        boxShadow: '0 4px 8px rgba(0,0,0,0.15)', // Slightly increased shadow on hover
    },
    '&:active': {
        transform: 'translateY(0)',
        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    },
}));

// Wrapper to control the aspect ratio and size of the image
const ImageWrapper = styled(Box)(({ theme }) => ({
    // width: '100%',
    // paddingTop: '100%', // This ensures a square aspect ratio
    overflow: 'hidden',
    alignSelf: "center",
    height: "330px",
    position: 'relative',
    '& > img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    }
}));

const CommunityBadge = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    borderRadius: '50%',
    padding: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export default ProductHistoryCard;
