import React from 'react';
import { Box, Typography, Badge } from "@mui/material";
import { styled, keyframes } from '@mui/material/styles';
import { ProductRequest } from "../../../api/models/product-request";

import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

function EmptyProductHistory() {
    return (
        <EnhancedHistoryContainer>
            <AddShoppingCartIcon style={{ fontSize: '40px', color: '#1f4be9' }} />
            <EnhancedHistoryHeader>
                No Items in Your History
            </EnhancedHistoryHeader>
            <EnhancedHistoryText>
                Start adding products by entering a URL to discover the best discounts!
            </EnhancedHistoryText>
        </EnhancedHistoryContainer>
    );
}

export default EmptyProductHistory;

const EnhancedHistoryContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: theme.spacing(5),
    gridColumn: '1 / -1',
    backgroundColor: '#f7f7f7',
    borderRadius: '12px',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s ease-in-out',
    '&:hover': {
        boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
    },
}));

const EnhancedHistoryHeader = styled(Typography)(({ theme }) => ({
    color: '#333',
    fontSize: '28px',
    fontWeight: 700,
    marginBottom: theme.spacing(2),
}));

const EnhancedHistoryText = styled(Typography)(({ theme }) => ({
    color: '#555',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '28px',
}));
