import { ProductAttribute } from "./product-offer";

export interface ProductRequest {
    id: number;
    searchTerm: string;
    productAttributes: ProductAttribute[];
    status: string;
    price: number;
    updateDate: Date;
    description: string;
    slug: string;
    visibility: ProductRequestVisibility;
}

export enum ProductRequestStatus {
    Pending = 0,
    InProgress = 1,
    Completed = 2,
    Failed = 3
}

export enum ProductRequestVisibility {
    Public = 0,
    Private = 1
}
