const ArrowIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M14.589 16.0032L5.98242 7.39664L7.39663 5.98242L16.0032 14.589V7.00324H18.0032V18.0032H7.00324V16.0032H14.589Z"
        fill="#1B222D"
      />
    </svg>
  );
};

export default ArrowIcon;
