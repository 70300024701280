import { Card, CardContent, Typography, Box, CardMedia } from "@mui/material";
import { styled } from '@mui/material/styles';
import { ProductRequest } from "../../../api/models/product-request";
import { RelativeDateDisplay } from "../../Shared/RelativeDateDisplay";
import { getImageUrl } from "../../../api/utils/productRequestUtils";
import { useAuth, useCurrencySymbol } from "../../../api/utils";

export interface ProductHistoryDrawerCardProps {
    request: ProductRequest;
    onClick: () => void;
}

export const ProductHistoryDrawerCard = ({ request, onClick }: ProductHistoryDrawerCardProps) => {
    const currencySymbol = useCurrencySymbol(request?.productAttributes || []);

    return (
        <StyledCard onClick={onClick}>
            <CardContent sx={{ paddingBottom: '0px' }}>
                <Box display="flex" flexDirection="column">
                    <CardMedia
                        component="img"
                        height="140"
                        image={getImageUrl(request)}
                        alt="Product Logo"
                    />
                    <Typography variant="body1" component="div" color="textPrimary" fontWeight="bold" gutterBottom>
                        {request.searchTerm}
                    </Typography>
                    <Box display="flex" alignItems="center" justifyContent="space-between" mt="auto">
                        <Typography variant="subtitle1" color="primary">
                            {currencySymbol}{request.price}
                        </Typography>
                        <RelativeDateDisplay
                            date={request.updateDate}
                            typographyProps={{
                                variant: "caption",
                                sx: { fontSize: '10px' },
                                color: "textSecondary",
                                noWrap: true
                            }}
                        />
                    </Box>
                </Box>
            </CardContent>
        </StyledCard>
    );
};

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: "200px", // Keep the card compact for the drawer
    minWidth: "200px", // Keep the card compact for the drawer
    margin: theme.spacing(1),
    cursor: 'pointer',
    transition: 'transform 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
    },
    '&:active': {
        transform: 'scale(1)',
    },
    boxShadow: 'none', // Remove box shadow
    border: `1px solid ${theme.palette.divider}`, // Add a subtle border
    borderRadius: theme.shape.borderRadius, // Use theme's border radius
}));