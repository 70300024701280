import React from "react";
import Title from "./Title/Title";
import Links from "./Links/Links";
import Actions from "./Actions/Actions";
import "./Navbar.css";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../common/Button/Button";
import { useNavigate } from "react-router-dom";

interface NavbarProps { }

const Navbar: React.FC<NavbarProps> = () => {
  const { loginWithRedirect, isAuthenticated, isLoading, logout } = useAuth0();
  const navigate = useNavigate();

  const onClick = () => {
    navigate('/app');
  }

  return (
    <nav className="navbar">
      <Title />
      <Links />
      <div>
        <Button onClick={onClick} text="Try it out" />
      </div>
    </nav>
  );
};

export default Navbar;
