import Actions from "./Waitlist/Actions";
import "./HeroSection.css";

const IntroText = () => {
  return (
    <div className="intro-container">
      <div className="intro-text-container">
        <div className="intro-text-header-container">
          <div className="intro-text-header-top">Smart Shopping</div>
          <div className="intro-text-header-bottom">Assistant.</div>
        </div>
        <div className="intro-text-bottom">
          Our assistant finds the best clothing deals whenever you need them.
        </div>
      </div>
    </div>
  );
};

export default IntroText;
