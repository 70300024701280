import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Landing from "./components/Landing/Landing";
import "./App.css";
import Dashboard from "./components/Dashboard/Dashboard";
import { ProductDetailsDisplay } from "./components/Product/ProductDetailsDisplay";
import AppLayout from "./components/Shared/AppLayout";
import { AuthProvider, useAuth } from './api/utils';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

function CountryRedirect() {
  const { country } = useAuth();
  const navigate = useNavigate();
  const { countryParam } = useParams();

  useEffect(() => {
    if (country && country !== countryParam) {
      console.log("country", country)
      navigate(`/${country}/app/`, { replace: true });
    }
  }, [country, countryParam, navigate]);

  return null;
}

function AppRoutes() {
  const { country } = useAuth();

  if (!country) {
    return <div>Loading...</div>; // Or some other loading indicator
  }

  return (
    <Routes>
      <Route path="/" element={<Landing />} />

      <Route path="/app" element={<CountryRedirect />} />

      <Route
        path=":countryParam/app/"
        element={
          <AppLayout>
            <Dashboard />
          </AppLayout>
        }
      />

      <Route
        path=":countryParam/app/search/:slug"
        element={
          <AppLayout>
            <ProductDetailsDisplay />
          </AppLayout>
        }
      />

      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
