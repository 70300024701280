import useAxios from 'axios-hooks';
import { GuestLoginResponse } from '../models/auth';

export const useAuthService = () => {
    return {
        useGuestLogin: () => useAxios<GuestLoginResponse>({
            url: '/api/auth/guest',
            method: 'POST'
        }, { manual: true }),
    };
};