import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import ProductHistoryCard from "./ProductHistoryCard";
import EmptyProductHistory from "./EmptyProductHistory";
import { useProductService } from "../../../api/services/productSearchService";
import { useAuth } from "../../../api/utils";

function ProductHistoryPanel() {
    const [isInWishlist, setIsInWishlist] = useState(false);
    const navigate = useNavigate();
    const { country } = useAuth();
    const { useGetProductRequests } = useProductService();
    const [{ data: productRequests, loading, error }, getProductRequests] = useGetProductRequests();

    useEffect(() => {
        getProductRequests();
    }, []);

    const handleToggleWishlist = (id: number) => {
        setIsInWishlist(!isInWishlist);
    };

    const handleClick = (slug: string) => {
        navigate(`/${country}/app/search/${slug}`);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error loading product history</div>;
    }

    return (
        <CustomBox>
            {!productRequests || productRequests.length === 0 ? (
                <EmptyProductHistory />
            ) : (
                productRequests.map((product) => (
                    <ProductHistoryCard
                        key={product.id}
                        request={product}
                        onClick={() => handleClick(product.slug)}
                        isInWishlist={isInWishlist}
                        onToggleWishlist={() => handleToggleWishlist(product.id)}
                    />
                ))
            )}
        </CustomBox>
    );
}

const CustomBox = styled(Box)(({ theme }) => ({
    display: 'grid',
    gap: theme.spacing(1.5),
    padding: theme.spacing(1.25),
    gridTemplateColumns: 'repeat(auto-fill, minmax(330px, 1fr))',
    maxWidth: '100%',
}));

export default ProductHistoryPanel;
