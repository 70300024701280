import React, { useEffect, useState } from "react";
import { Box, styled } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import AppHeader from "./AppHeader";
import { ProductHistoryDrawerCard } from "../Dashboard/ProductHistory/ProductHistoryDrawerCard"; // Import the new component
import { useProductService } from "../../api/services/productSearchService"; // Import the product service

interface Props {
    children: React.ReactNode;
}

function AppLayout({ children }: Props) {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const { useGetProductRequests } = useProductService();
    const [{ data: productRequests, loading, error }, refreshProductRequests] = useGetProductRequests();
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location


    // Determine if the drawer should be displayed based on the current route
    const shouldDisplayDrawer = location.pathname !== '/app';

    const handleClick = (slug: string) => {
        navigate(`/app/search/${slug}`);
    };

    useEffect(() => {
        if (isDrawerOpen) {
            refreshProductRequests();
        }
    }, [isDrawerOpen]);

    return (
        <LayoutContainer>
            <AppHeader
                isDrawerOpen={isDrawerOpen}
                onDrawerChange={setIsDrawerOpen}
                shouldDisplayDrawer={shouldDisplayDrawer} // Pass the prop
            >
                <DrawerContent>
                    {loading && <div>Loading...</div>}
                    {error && <div>Error loading product history</div>}
                    {productRequests && productRequests.map((product) => (
                        <ProductHistoryDrawerCard
                            key={product.id}
                            request={product}
                            onClick={() => {
                                handleClick(product.slug);
                                setIsDrawerOpen(false);
                            }}
                        />
                    ))}
                </DrawerContent>
            </AppHeader>
            <MainContent>
                {children}
            </MainContent>
        </LayoutContainer>
    );
}

const LayoutContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
});

const MainContent = styled(Box)({
    flexGrow: 1,
    overflow: 'hidden',
});

const DrawerContent = styled(Box)({
    padding: '16px',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    width: '200px', // Increase the width of the drawer
    justifyContent: "center",
    alignItems: "center",
});

export default AppLayout;
