import React from "react";
import Chip from "./Chip";
import Actions from "../HeroSection/Waitlist/Actions";

const AboutSection: React.FC = () => {
  return (
    <div className="about-section-container" id='about-section-container'>
      <div className="about-info-container">
        <div className="about-info-text-container">
          <div className="about-info-title">What is it about?</div>
          <div className="about-info-description">
            <p>
              Looking for the best deal on a product? Simply share the URL with our shopping assistant,
              and we'll search across a wide range of e-commerce platforms—including Farfetch, ASOS, Lyst, eBay, SSENSE, and many more—
              to find the best price available. No more endless searching or overspending—let us handle the hard work for you.
              Currently focused on clothing, but we're expanding to other categories soon.
            </p>
          </div>
        </div>
        <div className="about-info-other-container">
          <div className="about-link-text">
            Are you
          </div>
          <div className="about-chips-container">
            <Chip text="Overwhelmed by price comparisons?" />
            <Chip text="Tired of daily deal hunting?" />
            <Chip text="Worried about overspending online?" />
            <Chip text="Frustrated with endless searches?" />
            <Chip text="Spending too much time shopping?" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;
