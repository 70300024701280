import React, { useState, useEffect } from "react";
import { Card, CardContent, Typography, Box, CardMedia, Tooltip, IconButton, Chip, Link } from "@mui/material";
import { styled } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info'; // Info icon for price indication
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'; // Wishlist icon outline
import FavoriteIcon from '@mui/icons-material/Favorite'; // Wishlist icon filled
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonAddIcon from '@mui/icons-material/PersonAdd'; // Import the icon
import { ProductOffer, ProductAttributeNames, Provider, getProviderName, UserProductOfferType } from "../../api/models/product-offer";
import { getAttributeValue } from "../../api/models/seo.json";
import { useProductService } from "../../api/services/productSearchService";
import { getCurrencySymbol, useAuth, useCurrencySymbol } from "../../api/utils";
import { request } from "http";

export interface ProductHistoryCardProps {
    offer: ProductOffer;
    isBestOffer: boolean;
}

const ProductOfferCard = ({ offer, isBestOffer }: ProductHistoryCardProps) => {
    const [lowQualityLoaded, setLowQualityLoaded] = useState(false);
    const [highQualityLoaded, setHighQualityLoaded] = useState(false);
    const { useProductOfferRedirect } = useProductService();
    const [{ data: redirect }, executeProductOfferRedirect] = useProductOfferRedirect(offer.id);

    const lowQualityImage = getAttributeValue(offer.productAttributes, ProductAttributeNames.LowQualityImageUrl);
    const highQualityImage = getAttributeValue(offer.productAttributes, ProductAttributeNames.ImageUrl);

    // Fallback image in case both low and high quality images are unavailable
    const fallbackImage = "https://via.placeholder.com/300x300?text=No+Image";

    const currencySymbol = useCurrencySymbol(offer.productAttributes);

    useEffect(() => {

        if (lowQualityImage) {
            const lowQualityImg = new Image();
            lowQualityImg.src = lowQualityImage;
            lowQualityImg.onload = () => {
                setLowQualityLoaded(true);

                if (highQualityImage) {
                    const highQualityImg = new Image();
                    highQualityImg.src = highQualityImage;
                    highQualityImg.onload = () => {
                        setHighQualityLoaded(true);
                    };
                    highQualityImg.onerror = () => {
                        console.error("Failed to load high quality image");
                    };
                }
            };
            lowQualityImg.onerror = () => {
                console.error("Failed to load low quality image");
                // If low quality image fails, try loading high quality image
                if (highQualityImage) {
                    const highQualityImg = new Image();
                    highQualityImg.src = highQualityImage;
                    highQualityImg.onload = () => {
                        console.log("High quality image loaded after low quality failed");
                        setHighQualityLoaded(true);
                    };
                }
            };
        } else if (highQualityImage) {
            // If no low quality image, load high quality image directly
            const highQualityImg = new Image();
            highQualityImg.src = highQualityImage;
            highQualityImg.onload = () => {
                setHighQualityLoaded(true);
            };
        }
    }, [lowQualityImage, highQualityImage]);

    const handleProviderClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        executeProductOfferRedirect();
        const url = offer.regularItemUrl.startsWith('http') ? offer.regularItemUrl : `https://${offer.regularItemUrl}`;
        window.open(url, '_blank');
    };

    const parsePrice = (price: string): number => {
        // Remove currency symbols and spaces
        const cleanPrice = price.replace(/[$€£\s]/g, '');
        // Replace comma with dot if comma is used as decimal separator
        const normalizedPrice = cleanPrice.includes(',') ? cleanPrice.replace(',', '.') : cleanPrice;
        return parseFloat(normalizedPrice);
    };

    const fullPrice = parsePrice(getAttributeValue(offer.productAttributes, ProductAttributeNames.FullPrice));
    const salePrice = parsePrice(getAttributeValue(offer.productAttributes, ProductAttributeNames.SalePrice));
    const discountPercentage = salePrice && salePrice < fullPrice ? Math.round((1 - salePrice / fullPrice) * 100) : 0;

    const renderAttribute = (label: string, value: string) => {
        if (value) {
            return (
                <Typography variant="body2" color="textSecondary">
                    <strong>{label}:</strong> {value}
                </Typography>
            );
        }
        return null;
    };

    const formatPrice = (price: string | number): string => {
        // Convert to number if it's a string
        const numPrice = typeof price === 'string' ? parseFloat(price.replace(/,/g, '')) : price;

        // Check if the price has decimal places
        if (Number.isInteger(numPrice)) {
            // For whole numbers, format without decimal places
            return numPrice.toLocaleString('en-US');
        } else {
            // For decimal numbers, format with up to 2 decimal places
            return numPrice.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        }
    };

    console.log(currencySymbol);

    return (
        <StyledCard onClick={handleProviderClick} isUserGenerated={offer.type === UserProductOfferType.GeneratedFromLink} isBestPrice={isBestOffer}>
            <ImageWrapper>
                <CardMedia
                    component="img"
                    image={lowQualityImage || highQualityImage || fallbackImage}
                    alt={getAttributeValue(offer.productAttributes, ProductAttributeNames.Title)}
                    sx={{
                        objectFit: 'cover',
                        width: '100%',
                        height: '100%',
                        filter: (!lowQualityLoaded && !highQualityLoaded) ? 'blur(20px)' : 'none',
                        transition: 'filter 0.3s ease-out',
                    }}
                />
                {highQualityLoaded && highQualityImage && (
                    <CardMedia
                        component="img"
                        image={highQualityImage}
                        alt={getAttributeValue(offer.productAttributes, ProductAttributeNames.Title)}
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                            opacity: 1,
                            transition: 'opacity 0.3s ease-in',
                        }}
                    />
                )}
                <ProviderChip
                    label={offer.providerEntity?.name || "Unknown"}
                    color="primary"
                    size="small"
                    onClick={handleProviderClick}
                    icon={<OpenInNewIcon fontSize="small" />}
                />
                {/* {offer.type === UserProductOfferType.GeneratedFromLink && (
                    <Tooltip title="Added by user" arrow>
                        <ItemFromLinkIcon />
                    </Tooltip>
                )} */}
            </ImageWrapper>
            <CardContent>
                <Tooltip title={getAttributeValue(offer.productAttributes, ProductAttributeNames.Title)} arrow>
                    <Typography
                        variant="subtitle1"
                        component="div"
                        gutterBottom
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 500,
                            lineHeight: 1.2,
                            height: '2.4em',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            color: theme => theme.palette.text.primary,
                            fontFamily: theme => theme.typography.fontFamily,
                            letterSpacing: '0.01em',
                            textShadow: '0 1px 1px rgba(0,0,0,0.05)',
                        }}
                    >
                        {getAttributeValue(offer.productAttributes, ProductAttributeNames.Title)}
                    </Typography>
                </Tooltip>
                <Box style={{ marginTop: "auto" }} display="flex" alignItems="center" mb={1}>
                    {salePrice && discountPercentage > 0 ? (
                        <>
                            <Typography variant="h6" color="error" fontWeight="bold" mr={1}>
                                {currencySymbol}{formatPrice(salePrice)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through' }} mr={1}>
                                {currencySymbol}{formatPrice(fullPrice)}
                            </Typography>
                            <Chip
                                label={`${discountPercentage}% OFF`}
                                color="error"
                                size="small"
                                sx={{ fontWeight: 'bold' }}
                            />
                        </>
                    ) : (
                        fullPrice ? <Typography variant="h6" color="primary" mr={1}>
                            {currencySymbol}{formatPrice(fullPrice)}
                        </Typography> : <Typography variant="h6" color="error" mr={1}>
                            Price unavailable
                        </Typography>
                    )}
                    {/* <Tooltip title={getPriceTooltip(salePrice < fullPrice ? salePrice : fullPrice)}>
                        <InfoIcon sx={{ color: 'info.main', ml: 1 }} />
                    </Tooltip> */}
                </Box>
                {renderAttribute("Brand", getAttributeValue(offer.productAttributes, ProductAttributeNames.Brand))}
                {renderAttribute("Gender", getAttributeValue(offer.productAttributes, ProductAttributeNames.Gender))}
                {renderAttribute("Material", getAttributeValue(offer.productAttributes, ProductAttributeNames.Material))}
                {renderAttribute("Color", getAttributeValue(offer.productAttributes, ProductAttributeNames.Color))}
                {renderAttribute("Size", getAttributeValue(offer.productAttributes, ProductAttributeNames.Size))}
                {renderAttribute("Condition", getAttributeValue(offer.productAttributes, ProductAttributeNames.Condition))}
                {renderAttribute("Seller", getAttributeValue(offer.productAttributes, ProductAttributeNames.Seller))}
                {renderAttribute("Description", getAttributeValue(offer.productAttributes, ProductAttributeNames.Description))}
                {renderAttribute("Stock", getAttributeValue(offer.productAttributes, ProductAttributeNames.StockQuantity))}
                {getAttributeValue(offer.productAttributes, ProductAttributeNames.SellerFeedbackScore) && (
                    <Typography variant="body2" color="textSecondary">
                        <strong>Seller Feedback:</strong> {getAttributeValue(offer.productAttributes, ProductAttributeNames.SellerFeedbackScore)} ({getAttributeValue(offer.productAttributes, ProductAttributeNames.SellerFeedbackPercentage)})
                    </Typography>
                )}
                {getAttributeValue(offer.productAttributes, ProductAttributeNames.InStock) === 'true' && (
                    <Chip label="In Stock" color="success" size="small" sx={{ mt: 1 }} />
                )}

            </CardContent>
        </StyledCard>
    );
};


const StyledCard = styled(Card)<{ isUserGenerated: boolean, isBestPrice: boolean }>(({ theme, isUserGenerated, isBestPrice }) => ({
    maxWidth: "350px",
    minWidth: "350px",
    margin: theme.spacing(2),
    cursor: 'pointer',
    transition: 'all 0.2s ease-in-out',
    borderTop: isBestPrice
        ? '5px solid #4caf50' // Green border for the best price
        : isUserGenerated
            ? '5px solid #ff9800' // Orange border for user-generated offers
            : 'none', // No border for regular offers
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)', // Regular box shadow
    '&:hover': {
        transform: 'translateY(-4px)',
        boxShadow: '0 4px 8px rgba(0,0,0,0.15)', // Increased shadow on hover
    },
}));


// Wrapper to control the aspect ratio and size of the image
const ImageWrapper = styled(Box)(({ theme }) => ({
    // width: '100%',
    // paddingTop: '100%', // This ensures a square aspect ratio
    overflow: 'hidden',
    alignSelf: "center",
    height: "350px",
    position: 'relative',
    '& > img': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    }
}));

const ProviderChip = styled(Chip)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    zIndex: 1,
    cursor: 'pointer',
    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

const ItemFromLinkIcon = styled(PersonAddIcon)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    padding: 5,
    borderRadius: "50%"
}));


export default ProductOfferCard;
