import React from "react";
import Logo from "./Logo";
import "../Navbar.css";

interface TitleProps {
  className?: string;  // Optional className prop
}

const Title: React.FC<TitleProps> = ({ className = "" }) => {
  return (
    <div className={`title-container ${className}`}>
      <Logo />
      <div className="title-text">Scouter</div>
    </div>
  );
};

export default Title;
